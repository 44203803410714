import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { withRouter, useHistory } from 'react-router-dom'
import { Grid, Icon, Button } from 'semantic-ui-react'
import classNames from 'classnames'

import FormComponent from 'Helpers/form'
import { UserPhoto } from 'Common'
import { determineLastActivityLabel, scrollTo } from 'Utils'

import UserAnswer from 'Pages/Subjects/ManageOutcomes/UserAnswer'
import StatusIcon from 'Pages/Subjects/ManageOutcomes/StatusIcon'

const OutcomeItem = ({
	isExpand,
	isSelected,
	openItem,
	closeItem,
	selectItem,
	manager,
	subjectSectionType,
	outcome,
	isExport,
	learning_stats,
}) => {
	const history = useHistory()
	const [userAnswers, setUserAnswers] = useState([])
	const [correctAnswers, setCorrectAnswers] = useState([])
	const managerEvidenceDaysLeft = outcome.managerEvidence
		? determineLastActivityLabel(outcome.managerEvidence.created_at)
		: null
	const learnerEvidenceDaysLeft = outcome.userEvidence
		? determineLastActivityLabel(outcome.userEvidence.created_at)
		: null
	const learningActiveDaysLeft = outcome.lastActive ? determineLastActivityLabel(outcome.lastActive.created_at) : null

	const scrollToEvidence = evidenceId => {
		const evidenceEl = document.getElementById(`evidence-${evidenceId}`)

		if (!evidenceEl) {
			return false
		}

		evidenceEl.classList.add('flesh')
		setTimeout(() => {
			evidenceEl.classList.remove('flesh')
		}, 150)

		const containerEl = document.querySelector('.main-content > .container')

		if (!containerEl) {
			return false
		}

		scrollTo(evidenceEl.offsetTop + evidenceEl.offsetHeight - containerEl.offsetHeight / 2, containerEl)
	}

	useEffect(() => {
		let uniqueNames = []
		let filteredUserAnswers = outcome.userAnswers.map(answer => {
			if (!userAnswers.includes(answer.question.name)) {
				uniqueNames.push(answer.question.name)
				return answer
			}
		})
		let filterCorrectAnswers = outcome.userAnswers.filter(res => res.result)
		setCorrectAnswers(filterCorrectAnswers)
		setUserAnswers(filteredUserAnswers)
	}, [outcome])
	const viewLearning = () => {
		if (!manager) {
			history.push({
				pathname: location.pathname + '/learn',
				state: {
					learning_link: outcome.learning_link,
				},
			})
		}
	}
	const countCorrectValues = (userAnswers, askQuestionsCount) => {
		let count = 0
		const ttlAnswers = userAnswers.length
		for (let i = 0; i < ttlAnswers; i++) {
			if (userAnswers[i].result) {
				count++
			}
		}

		return count >= askQuestionsCount
	}

	return (
		<li className={classNames('outcome-wrap', { open: isExport || isExpand })}>
			<div className="outcome-item">
				<div className="actions">
					<FormComponent.Checkbox
						name="multichoice"
						checked={isSelected}
						onChange={selectItem}
						className="outcomeCheck"
					/>

					<button type="button" className="expand" onClick={isExpand ? closeItem : openItem}>
						<i className={`fas fa-${isExpand ? 'minus' : 'plus'}-square`} />
					</button>
				</div>
				<div className="ref">
					<span>{outcome.reference}</span>
				</div>
				<div className="outcome-list-name">
					<svg width="10" fill="#F4F6FC" xmlns="http://www.w3.org/2000/svg" className="svg-height">
						<line x1="50" y1="0%" x2="50" y2="50%" stroke="gray" strokeWidth="2" />
					</svg>
					<p>{outcome.name}</p>
				</div>
				<div className="status status-align">
					{learning_stats.learning_link_count > 0 && (
						<>
							<div className="learning-asset-align">
								<svg
									width="10"
									fill="#F4F6FC"
									height="100"
									className="svg-height"
									xmlns="http://www.w3.org/2000/svg"
								>
									<line x1="50" y1="0%" x2="50" y2="50%" stroke="gray" strokeWidth="2" />
								</svg>
								{outcome.learning_link !== '' &&
								outcome.learning_link !== null &&
								subjectSectionType === 'Iframe' ? (
									outcome.lastActive ? (
										<div className="flex-assesment">
											<Button
												className="flex-assesment textalignstart view-learning-btn"
												onClick={viewLearning}
											>
												<StatusIcon status="learning_viewed_file" />
												<StatusIcon status="learning_viewed" />
												<div className="flex-assesment-start hidden-sm">
													<span className="hidden-sm">Learning viewed</span>
												</div>
											</Button>
										</div>
									) : !manager ? (
										<div className="flex-assesment">
											<div className="flex-assesment-start ">
												<Button
													className="flex-assesment textalignstart start-learning-btn"
													onClick={viewLearning}
												>
													<StatusIcon status="start_learning_file" />
													<StatusIcon status="start_learning" />
													<div className="flex-assesment-start hidden-sm">
														<span className="hidden-sm">Start Learning</span>
													</div>
												</Button>
											</div>
										</div>
									) : (
										<div className="flex-assesment">
											<svg
												className="margin-props"
												xmlns="http://www.w3.org/2000/svg"
												height="24"
												viewBox="0 0 384 512"
											>
												<style>{`svg{fill:#0067ac}`}</style>
												<path
													d={
														'M0 64C0 28.7 28.7 0 64 0H224V128c0 17.7 ' +
														'14.3 32 32 32H384V448c0 35.3-28.7 64-64 64H64c-35.3 ' +
														'0-64-28.7-64-64V64zm384 64H256V0L384 128z'
													}
												/>
											</svg>
											<div className="flex-assesment-start hidden-sm">
												<svg
													xmlns="http://www.w3.org/2000/svg"
													height="18"
													viewBox="0 0 512 512"
												>
													<path
														d={
															'M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 ' +
															'512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 ' +
															'47-47 c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 ' +
															'47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 ' +
															' 0 l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 ' +
															' 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z'
														}
														style={{ fill: '#8aa2d3' }}
													/>
												</svg>
												<p className="textalignstart hidden-sm">Not Viewed</p>
											</div>
										</div>
									)
								) : (
									<div className="flex-assesment">
										<StatusIcon status="no_learning_file" />
										<StatusIcon status="no_learning" />
										<div className="flex-assesment-start">
											<p className="textalignstart hidden-sm">No Learning</p>
										</div>
									</div>
								)}
							</div>
						</>
					)}
					<div className="learning-asset-align-asset">
						<StatusIcon
							status={
								outcome.status !== 'success' && outcome.ask_questions_count === 0
									? 'success'
									: outcome.status
							}
							tooltip={
								outcome.status === 'success' &&
								outcome.managerEvidence &&
								outcome.managerEvidence.sign_off
									? 'Signed-Off'
									: outcome.status !== 'success' && outcome.ask_questions_count === 0
									? 'No questions'
									: ''
							}
						/>
						{outcome.status === 'success' &&
						outcome.managerEvidence &&
						(outcome.observationStatus !== 'observation_done' || outcome.userPivot) &&
						outcome.managerEvidence.sign_off ? (
							'Signed-Off'
						) : outcome.ask_questions_count === 0 ? (
							<>
								<p className="textalignend hidden-sm">No questions</p>
							</>
						) : countCorrectValues(outcome.userAnswers, outcome.ask_questions_count) ? (
							<>
								<p style={{ color: 'green' }}>
									{correctAnswers.length}/{outcome.ask_questions_count}
								</p>
								<p className="hidden-sm" style={{ color: 'green' }}>
									Correct
								</p>
							</>
						) : outcome.userAnswers.length === 0 ? (
							<p className="textalignend hidden-sm">Not Started</p>
						) : (
							<>
								<p style={{ color: 'red' }}>
									{correctAnswers.length}/{outcome.ask_questions_count}
								</p>
								<p className="textalignend hidden-sm" style={{ color: 'red' }}>
									Correct
								</p>
							</>
						)}
					</div>
					{outcome.observable ? (
						<StatusIcon status={outcome.observationStatus} />
					) : (
						<li className="li-empty-tag" style={{ minWidth: '32px' }}>
							{' '}
							&nbsp;{' '}
						</li>
					)}
				</div>
			</div>

			<div className="outcome-info">
				{!userAnswers.length && (
					<div className="no-answer">
						<span>No answer yet</span>
					</div>
				)}

				<Grid>
					<Grid.Column mobile={16} tablet={8} computer={7}>
						{userAnswers &&
							userAnswers.map(userAnswer => <UserAnswer {...userAnswer} key={userAnswer.id} />)}
						{(outcome.lastActive || outcome.learning_link) && (
							<div className="info-item">
								<h5>
									<span className="icon">
										<i className="fas fa-angle-down" />
									</span>
									Learning
								</h5>
								{outcome.lastActive && (
									<span>
										Last viewed: <span className="blue-text">{learningActiveDaysLeft}</span>
									</span>
								)}
								{!manager && outcome.learning_link && subjectSectionType === 'Iframe' && (
									<span className="learning-link">
										<Button type="button" size="small" onClick={viewLearning}>
											<Icon name="eye" style={{ color: 'white' }} />
											<span>View learning</span>
										</Button>
									</span>
								)}
							</div>
						)}
					</Grid.Column>
					<Grid.Column mobile={16} tablet={8} computer={7} floated="right">
						{(outcome.userEvidence || outcome.managerEvidence) && (
							<div className="info-item">
								<h5>
									<span className="icon">
										<i className="fas fa-angle-down" />
									</span>
									Evidence
								</h5>
								{outcome.userEvidence && (
									<div className="evidence-item">
										<span className="dark-text">
											Learner Evidence{' '}
											<span className="blue-text">{learnerEvidenceDaysLeft}</span>
										</span>
										<div>
											<span className="icon">
												<i className="far fa-eye" />
											</span>
											{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
											<a
												className="blue-text"
												href="javascript:void(0)"
												onClick={() => scrollToEvidence(outcome.userEvidence.id)}
											>
												View evidence
											</a>
										</div>
									</div>
								)}

								{outcome.managerEvidence && (
									<div className="evidence-item">
										<div className="manager-evidence">
											<span className="dark-text">
												Manager Evidence{' '}
												<span className="blue-text">{managerEvidenceDaysLeft}</span>
											</span>
											<div className="manager">
												{outcome.managerEvidence.user && (
													<>
														<UserPhoto
															className="photo"
															user={outcome.managerEvidence.user}
															size="extraMini"
															square
														/>
														<span>
															{`${outcome.managerEvidence.user.first_name} 
														${outcome.managerEvidence.user.last_name}`}
														</span>
													</>
												)}
											</div>
										</div>
										<div>
											<span className="icon">
												<i className="far fa-eye" />
											</span>
											{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
											<a
												className="blue-text"
												href="javascript:void(0)"
												onClick={() => scrollToEvidence(outcome.managerEvidence.id)}
											>
												View evidence
											</a>
										</div>
									</div>
								)}
							</div>
						)}
					</Grid.Column>
				</Grid>
			</div>
		</li>
	)
}

OutcomeItem.propTypes = {
	isExpand: PropTypes.bool.isRequired,
	isSelected: PropTypes.bool,
	subjectSectionType: PropTypes.string.isRequired,
	openItem: PropTypes.func.isRequired,
	closeItem: PropTypes.func.isRequired,
	manager: PropTypes.bool.isRequired,
	selectItem: PropTypes.func.isRequired,
	outcome: PropTypes.object.isRequired,
	isExport: PropTypes.bool.isRequired,
	learning_stats: PropTypes.object,
}
OutcomeItem.defaultProps = {
	isSelected: false,
	learning_stats: {},
}

export default withRouter(OutcomeItem)
